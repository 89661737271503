<template>
  <v-container class="pa-4" fluid>
    <v-row class="justify-center">
      <v-col cols="12" md="8">
        <v-card elevation="3">
          <v-card-title class="text-h5" style="justify-content: center"
            >Report To Generate</v-card-title
          >
          <v-card-text>
            <v-form ref="form" @submit.prevent="generateReport">
              <v-row dense align="center">
                <!-- Select Job -->
                <v-col cols="12" md="3">
                  <v-autocomplete
                    v-model="selectedOption"
                    :items="options"
                    label="Select Job"
                    dense
                    outlined
                    required
                    clearable
                    :rules="rules.required"
                  ></v-autocomplete>
                </v-col>

                <!-- Start Date -->
                <v-col cols="12" md="3">
                  <v-menu
                    v-model="startMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startDate"
                        label="Start Date"
                        dense
                        outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      @input="startMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <!-- End Date -->
                <v-col cols="12" md="3">
                  <v-menu
                    v-model="endMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="endDate"
                        label="End Date"
                        dense
                        outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      @input="endMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <!-- Subcontractor Hourly Rate -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="subcontractorRate"
                    label="Subcontractor Hourly Rate"
                    dense
                    outlined
                    type="number"
                    required
                    :rules="rules.required"
                  ></v-text-field>
                </v-col>
              </v-row>

              <!-- Manual Input Checkbox -->
              <v-row dense>
                <v-col cols="12">
                  <v-checkbox
                    v-model="manualInput"
                    label="Manual Inputs"
                    dense
                    @change="fetchManualInputs"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <!-- Manual Input Fields -->
              <v-row dense v-if="manualInput">
                <v-dialog v-model="showPopup" max-width="500">
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">Manual Inputs Already Exist</span>
                    </v-card-title>
                    <v-card-text>
                      Manual inputs are already stored for this job. Do you want
                      to edit them?
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="allowEdit">Yes</v-btn>
                      <v-btn text @click="closePopup">No</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="manualInputs.budgetarySaleNumber"
                    label="Budgetary Sale Number"
                    :readonly="!editableManualInputs"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="manualInputs.budgetaryLabourCost"
                    label="Budgetary Labour Cost"
                    :readonly="!editableManualInputs"
                    dense
                    outlined
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="manualInputs.budgetaryMaterialsCost"
                    label="Budgetary Material Cost"
                    :readonly="!editableManualInputs"
                    dense
                    outlined
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="manualInputs.budgetaryProfitMargin"
                    label="Budgetary Profit Margin"
                    :readonly="!editableManualInputs"
                    dense
                    outlined
                    type="number"
                  ></v-text-field>
                </v-col>
              </v-row>

              <!-- Generate Button -->
              <v-btn class="mt-4 btn" block @click="generateReport"
                >Generate</v-btn
              >
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Snackbar -->
    <v-snackbar v-model="showSnackbar" timeout="3000" top right>
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="showSnackbar = false">Close</v-btn>
      </template>
    </v-snackbar>

    <!-- Tabs Section -->
    <v-row class="mt-5">
      <v-col cols="12">
        <v-tabs v-model="activeTab" centered>
          <v-tab>Report</v-tab>
          <v-tab>Graphs</v-tab>
        </v-tabs>

        <v-tabs-items v-model="activeTab">
          <!-- Report Tab -->
          <v-tab-item>
            <v-card elevation="2" class="pa-4">
              <div v-if="isLoading" class="d-flex justify-center my-5">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  size="50"
                ></v-progress-circular>
              </div>
              <div v-else-if="!showReport" class="d-flex justify-center my-5">
                <h4>No report generated yet.</h4>
              </div>
              <div v-else>
                <h3>Job Profit and Loss Report</h3>
                <p>
                  <b>Gricon Industries Pty Ltd</b><br />183 Bergmans Road
                  Greenmount QLD 4751<br />0417900707
                </p>
                <p><b>Date From:</b> {{ reportData.startDate || "N/A" }}</p>
                <p><b>Date To:</b> {{ reportData.endDate || "N/A" }}</p>
                <p>
                  <b>Generated On:</b> {{ reportData.createdDate || "N/A" }}
                </p>

                <!-- Summary Table -->
                <v-simple-table class="mt-3">
                  <template v-slot:default>
                    <thead>
                      <tr style="background-color: #364b59">
                        <th style="color: #fff">Job Number</th>
                        <th style="color: #fff">Job Name</th>
                        <th style="color: #fff">Income ($)</th>
                        <th style="color: #fff">Cost ($)</th>
                        <th style="color: #fff">Expenses ($)</th>
                        <th style="color: #fff">Net Profit ($)</th>
                        <th style="color: #fff">Profit Margin (%)</th>
                        <th style="color: #fff">Total Labour Cost ($)</th>
                        <th style="color: #fff">Total Material Cost ($)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ reportData.jobNumber || "None" }}</td>
                        <td>{{ reportData.jobName || "None" }}</td>
                        <td>{{ reportData.Income || "0.00" }}</td>
                        <td>{{ reportData.CostOfSales || "0.00" }}</td>
                        <td>{{ reportData.Expenses || "0.00" }}</td>
                        <td>{{ reportData.NetProfit || "0.00" }}</td>
                        <td>{{ reportData.ProfitMargin || "0.00" }}</td>
                        <td>{{ reportData.TotalLabour || "0.00" }}</td>
                        <td>{{ reportData.TotalMaterials || "0.00" }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <!-- Detailed Table -->
                <v-simple-table class="mt-3">
                  <template v-slot:default>
                    <thead>
                      <tr style="background-color: #364b59">
                        <th></th>
                        <!-- New column without a heading -->
                        <th style="color: #fff">Category Code</th>
                        <th style="color: #fff">Category Name</th>
                        <th style="color: #fff">Selected Period ($)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(row, index) in groupedAndSortedAccountDetails"
                        :key="index"
                      >
                        <td v-if="row.isParent" style="font-weight: bold">
                          {{ row.ParentAccountName }}
                        </td>
                        <td v-else></td>
                        <td>{{ row.DisplayID }}</td>
                        <td>{{ row.Name }}</td>
                        <td>{{ row.Total }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <!-- Summary Section -->
                <!-- Summary Table -->
                <v-simple-table class="mt-3">
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td style="text-align: left; font-weight: bold">
                          Total Income
                        </td>
                        <td style="text-align: right; font-weight: bold">
                          {{ reportData.Income || "0.00" }}
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: left; font-weight: bold">
                          Total Cost of Sales
                        </td>
                        <td style="text-align: right; font-weight: bold">
                          {{ reportData.CostOfSales || "0.00" }}
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: left; font-weight: bold">
                          Gross Profit
                        </td>
                        <td style="text-align: right; font-weight: bold">
                          {{ reportData.GrossProfit || "0.00" }}
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: left; font-weight: bold">
                          Total Expense
                        </td>
                        <td style="text-align: right; font-weight: bold">
                          {{ reportData.Expenses || "0.00" }}
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: left; font-weight: bold">
                          Operating Profit
                        </td>
                        <td style="text-align: right; font-weight: bold">
                          {{
                            reportData.OperatingProfit ||
                            reportData.GrossProfit ||
                            "0.00"
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: left; font-weight: bold">
                          Net Profit
                        </td>
                        <td style="text-align: right; font-weight: bold">
                          {{ reportData.NetProfit || "0.00" }}
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: left; font-weight: bold">
                          Total of All Metrics
                        </td>
                        <td style="text-align: right; font-weight: bold">
                          {{ reportData.TotalAllMetrics || "0.00" }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-simple-table class="mt-3">
                  <template v-slot:default>
                    <thead>
                      <tr style="background-color: #364b59; text-align: center">
                        <th style="color: #fff">
                          <div>Permanent Labour Hourly Rate</div>
                          <div style="text-align: center">($)</div>
                        </th>
                        <th style="color: #fff">
                          <div>Permanent Labour Cost</div>
                          <div style="text-align: center">($)</div>
                        </th>
                        <th style="color: #fff">
                          <div>Permanent Labour Hours</div>
                          <div style="text-align: center">(hr)</div>
                        </th>
                        <th style="color: #fff">
                          <div>Permanent Labour Overtime Hours</div>
                          <div style="text-align: center">(1.5x)</div>
                        </th>
                        <th style="color: #fff">
                          <div>Permanent Labour Overtime Hours</div>
                          <div style="text-align: center">(2x)</div>
                        </th>
                        <th style="color: #fff">
                          <div>Subcontractor Labour Hourly Rate</div>
                          <div style="text-align: center">($)</div>
                        </th>
                        <th style="color: #fff">
                          <div>Subcontractor Labour Cost</div>
                          <div style="text-align: center">($)</div>
                        </th>
                        <th style="color: #fff">
                          <div>Subcontractor Labour Hours</div>
                          <div style="text-align: center">(hr)</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {{
                            labourHoursData.permanentLabourHourlyRate || "None"
                          }}
                        </td>
                        <td>
                          {{ labourHoursData.labourPermanentCost || "None" }}
                        </td>
                        <td>
                          {{ labourHoursData.labourPermanentHours || "0.00" }}
                        </td>
                        <td>
                          {{
                            labourHoursData.labourPermanentOvertime1_5xHours ||
                            "0.00"
                          }}
                        </td>
                        <td>
                          {{
                            labourHoursData.labourPermanentOvertime2xHours ||
                            "0.00"
                          }}
                        </td>
                        <td>
                          {{ labourHoursData.subcontactorHourRate || "0.00" }}
                        </td>
                        <td>
                          {{
                            labourHoursData.labourSubcontractorCost || "0.00"
                          }}
                        </td>
                        <td>
                          {{
                            labourHoursData.labourSubcontractorHours || "0.00"
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th
                          colspan="2"
                          style="
                            background-color: #5d8099;
                            text-align: left;
                            font-weight: bold;
                            color: #fff;
                            font-size: 20px;
                          "
                        >
                          Manual Inputs:
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style="font-weight: bold">Budgetary Sale Number</td>
                        <td style="font-weight: bold">
                          {{ labourHoursData.budgetarySaleNumber || "0" }}
                        </td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold">Budgetary Labour Cost</td>
                        <td style="font-weight: bold">
                          {{ labourHoursData.budgetaryLabourCost || "0" }}
                        </td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold">
                          Budgetary Material Cost
                        </td>
                        <td style="font-weight: bold">
                          {{ labourHoursData.budgetaryMaterialsCost || "0" }}
                        </td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold">
                          Budgetary Profit Margin
                        </td>
                        <td style="font-weight: bold">
                          {{ labourHoursData.budgetaryProfitMargin || "0" }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-card>
          </v-tab-item>

          <!-- Graphs Tab -->
          <v-tab-item>
  <v-card elevation="2" class="pa-4">
    <GraphComponent v-if="showReport" :reportData="reportData" :labourData = "labourHoursData" />
    <div v-else class="d-flex justify-center my-5">
      <h4>No graphs available yet.</h4>
    </div>
  </v-card>
</v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-row dense v-if="showReport" class="justify-end mt-3">
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="mt-4 btn1 small-download-btn"
        small
        v-bind="attrs"
        v-on="on"
      >
        Download Report
      </v-btn>
    </template>
    <v-list>
      <v-list-item @click="exportToPDF">
        <v-list-item-title>Download as PDF</v-list-item-title>
      </v-list-item>
      <v-list-item @click="exportToExcel">
        <v-list-item-title>Download as Excel</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</v-row>


  </v-container>
</template>

<script>
import axios from "axios";
import config from "../../../../config/app.json";
import jsPDF from "jspdf";
import "jspdf-autotable";
import GraphComponent from "../../../components/GraphComponent.vue";
export default {
  data() {
    return {
      options: [], // Job list fetched from /api/jobs/list
      selectedOption: null,
      startDate: null,
      endDate: null,
      subcontractorRate: null,
      manualInput: false,
      activeTab: 0, // Active tab (0 for Report, 1 for Graphs)
      isLoading: false, // Controls the circular loader
      showReport: false, // Toggles report visibility
      showSnackbar: false, // Controls the snackbar visibility
      snackbarMessage: "", // Message to show in the snackbar
      reportData: {}, // Store fetched report data
      labourHoursData: {}, // Store labour hours data
      manualInput: false,
      manualInputs: {
        budgetarySaleNumber: "",
        budgetaryLabourCost: "",
        budgetaryMaterialsCost: "",
        budgetaryProfitMargin: "",
      },
      showPopup: false,
      editableManualInputs: true, // Allow editing manual inputs
      startMenu: false,
      endMenu: false,
      rules: {
        required: [(v) => !!v || "This field is required"],
      },
    };
  },
  components: {
    GraphComponent,
  },
  computed: {
    groupedAndSortedAccountDetails() {
      // Check if `accountDetails` exists and is an array
      if (!this.reportData || !Array.isArray(this.reportData.accountDetails)) {
        return []; // Return an empty array to avoid errors
      }

      // Group the accounts by ParentAccount
      const groupedAccounts = {};
      this.reportData.accountDetails.forEach((account) => {
        const parentAccountName = account.ParentAccount || "Unknown";
        if (!groupedAccounts[parentAccountName]) {
          groupedAccounts[parentAccountName] = [];
        }
        groupedAccounts[parentAccountName].push(account);
      });

      // Sort the groups by ParentAccount (numerically by DisplayID of the first account in the group)
      const sortedGroups = Object.entries(groupedAccounts)
        .sort(([parentA], [parentB]) => {
          const codeA = groupedAccounts[parentA][0]?.DisplayID || "0";
          const codeB = groupedAccounts[parentB][0]?.DisplayID || "0";
          return parseInt(codeA) - parseInt(codeB);
        })
        .flatMap(([parentAccountName, accounts]) => {
          // Add the parent account name only once as the first row of the group
          return [
            { ParentAccountName: parentAccountName, isParent: true }, // Parent account row
            ...accounts.map((account) => ({
              ...account,
              ParentAccountName: parentAccountName, // Include parent account name in each account row
              isParent: false,
            })),
          ];
        });

      return sortedGroups;
    },
  },

  mounted() {
    // Fetch job list on component mount
    this.initializeJobs();
  },
  methods: {
    async initializeJobs() {
      const user = this.$store.getters.getUser;
      if (user.role === "project manager") {
        await this.fetchUserJobs(user.id);
      } else {
        await this.fetchJobs();
      }
    },
    async fetchManualInputs() {
      if (!this.manualInput) {
        // Reset manual inputs when the checkbox is unchecked
        this.manualInputs = {
          budgetarySaleNumber: "",
          budgetaryLabourCost: "",
          budgetaryMaterialsCost: "",
          budgetaryProfitMargin: "",
        };
        return;
      }

      try {
        const response = await axios.get(
          `${config.API_URL}/api/jobs/manual-inputs`,
          {
            params: {
              jobNumber: this.selectedOption,
            },
          }
        );

        if (Object.keys(response.data).length === 0) {
          // If no manual inputs are found, allow editing
          this.editableManualInputs = true;
        } else {
          // If manual inputs are found, show a pop-up
          this.manualInputs = response.data;
          this.editableManualInputs = false;
          this.showPopup = true;
        }
      } catch (error) {
        console.error("Error fetching manual inputs:", error);
        this.snackbarMessage = "Failed to fetch manual inputs.";
        this.showSnackbar = true;
      }
    },
    allowEdit() {
      this.editableManualInputs = true;
      this.showPopup = false;
    },
    closePopup() {
      this.editableManualInputs = false;
      this.showPopup = false;
    },
    // Fetch job list for the dropdown
    async fetchJobs() {
      try {
        this.isLoading = true;
        const response = await axios.get(`${config.API_URL}/api/jobs/list`);
        this.options = response.data.map((job) => ({
          value: job.Number,
          text: `${job.Number} - ${job.Name}`,
        })); // Map job list to include both Number and Name
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching job list:", error);
        this.snackbarMessage = "Failed to fetch job list.";
        this.showSnackbar = true;
        this.isLoading = false;
      }
    },
    async fetchUserJobs(userId) {
      try {
        this.isLoading = true;
        const response = await axios.get(
          `${config.API_URL}/api/userJobSection/get/${userId}`
        );
        const userJobNumbers = response.data.data.jobs.map((job) => job);
        const jobsResponse = await axios.get(`${config.API_URL}/api/jobs/list`);
        this.options = jobsResponse.data
          .filter((job) => userJobNumbers.includes(job.Number))
          .map((job) => ({
            value: job.Number,
            text: `${job.Number} - ${job.Name}`,
          }));
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching user jobs:", error);
        this.snackbarMessage = "Failed to fetch user jobs.";
        this.showSnackbar = true;
        this.isLoading = false;
      }
    },
    resetForm() {
  this.selectedOption = null;
  this.startDate = null;
  this.endDate = null;
  this.subcontractorRate = null;
  this.manualInput = false;
  this.manualInputs = {
    budgetarySaleNumber: "",
    budgetaryLabourCost: "",
    budgetaryMaterialsCost: "",
    budgetaryProfitMargin: "",
  };
    // Do not trigger validation, simply reset the form
    if (this.$refs.form) {
    this.$refs.form.reset();
  }
},

    // Generate the report
    async generateReport() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.isLoading = true;
      this.snackbarMessage = "Generating report...";
      this.showSnackbar = true;

      try {
        //Hitting Report generate endpoint
        const response = await axios.get(`${config.API_URL}/api/jobs/report`, {
          params: {
            jobNumber: this.selectedOption,
            startDate: this.startDate,
            endDate: this.endDate,
          },
        });

        //Hiiting Labour Hour endpoint
        const response_hours = await axios.post(
          `${config.API_URL}/api/jobs/labour-hours`,
          {
            hourlyRate: this.subcontractorRate,
            budgetarySaleNumber: this.manualInputs.budgetarySaleNumber || 0,
            budgetaryLabourCost: this.manualInputs.budgetaryLabourCost || 0,
            budgetaryMaterialsCost:
              this.manualInputs.budgetaryMaterialsCost || 0,
            budgetaryProfitMargin: this.manualInputs.budgetaryProfitMargin || 0,
          },
          {
            params: {
              jobNumber: this.selectedOption,
              startDate: this.startDate,
              endDate: this.endDate,
            },
          }
        );

        if (
          response.data &&
          response.data.accountDetails &&
          response_hours.data
        ) {
          this.reportData = response.data; // Store the fetched report data
          this.labourHoursData = response_hours.data;
          console.log("Report Data:", this.reportData);
          this.resetForm();
        } else {
          console.error(
            "API response is missing `accountDetails`:",
            response.data
          );
          this.reportData = { accountDetails: [] }; // Fallback to avoid errors
        }
        this.showReport = true;
        this.snackbarMessage = "Report generated successfully!";
        this.showSnackbar = true;
      } catch (error) {
        console.error("Error generating report:", error);
        this.snackbarMessage = "Failed to generate the report.";
        this.showSnackbar = true;
      } finally {
        this.isLoading = false;
      }
    },
    toggleManualInput() {
      if (!this.manualInput) {
        this.manualInputs = {
          budgetarySaleNumber: "",
          budgetaryLabourCost: "",
          budgetaryMaterialsCost: "",
          budgetaryProfitMargin: "",
        };
      }
    },
// Export report data to CSV
  exportToPDF() {
    if (!this.showReport || !this.reportData) {
      this.snackbarMessage = "No report available to download.";
      this.showSnackbar = true;
      return;
    }

    const doc = new jsPDF();

    // Add title and details
    doc.text("Gricon Industries Pty Ltd - Job Profit and Loss Report", 10, 10);
    doc.text("183 Bergmans Road, Greenmount QLD 4751", 10, 20);
    doc.text("Phone: 0417900707", 10, 30);
    doc.text(`Date From: ${this.reportData.startDate || "N/A"}`, 10, 40);
    doc.text(`Date To: ${this.reportData.endDate || "N/A"}`, 10, 50);
    doc.text(`Generated On: ${new Date().toLocaleString()}`, 10, 60);

    // Add Summary Table
    doc.autoTable({
      startY: 70,
      head: [["Job Number", "Job Name", "Income ($)", "Cost ($)", "Expenses ($)", "Net Profit ($)", "Profit Margin (%)", "Total Labour Cost ($)", "Total Material Cost ($)"]],
      body: [
        [
          this.reportData.jobNumber || "None",
          this.reportData.jobName || "None",
          this.reportData.Income || "0.00",
          this.reportData.CostOfSales || "0.00",
          this.reportData.Expenses || "0.00",
          this.reportData.NetProfit || "0.00",
          this.reportData.ProfitMargin || "0.00",
          this.reportData.TotalLabour || "0.00",
          this.reportData.TotalMaterials || "0.00",
        ],
      ],
    });

    // Add Detailed Table
    const detailsBody = this.groupedAndSortedAccountDetails.map((row) => [
      row.isParent ? row.ParentAccountName : "",
      row.DisplayID || " ",
      row.Name || " ",
      {content: row.Total || " ", styles: { halign: "right" }}
    ]);

    doc.autoTable({
      startY: doc.lastAutoTable.finalY + 10,
      head: [["-","Category Code", "Category Name", "Selected Period ($)"]],
      body: detailsBody,
    });

 
  // Add Metrics Table (aligned with labels on the left and values on the right)
  doc.autoTable({
    startY: doc.lastAutoTable.finalY + 10,
    body: [
      ["Total Income", { content: this.reportData.Income || "0.00", styles: { halign: "right" } }],
      ["Total Cost of Sales", { content: this.reportData.CostOfSales || "0.00", styles: { halign: "right" } }],
      ["Gross Profit", { content: this.reportData.GrossProfit || "0.00", styles: { halign: "right" } }],
      ["Total Expense", { content: this.reportData.Expenses || "0.00", styles: { halign: "right" } }],
      [
        "Operating Profit",
        { content: this.reportData.OperatingProfit || this.reportData.GrossProfit || "0.00", styles: { halign: "right" } },
      ],
      ["Net Profit", { content: this.reportData.NetProfit || "0.00", styles: { halign: "right" } }],
      ["Total of All Metrics", { content: this.reportData.TotalAllMetrics || "0.00", styles: { halign: "right" } }],
    ],
  });

  // Add Labour Hours Table (always on a new page)
  doc.addPage();
  doc.autoTable({
    startY: 10,
    head: [["Permanent Labour Hourly Rate ($)", "Permanent Labour Cost ($)", "Permanent Labour Hours (hr)", "Permanent Labour Overtime Hours (1.5x)", "Permanent Labour Overtime Hours (2x)", "Subcontractor Labour Hourly Rate ($)", "Subcontractor Labour Cost ($)", "Subcontractor Labour Hours (hr)"]],
    body: [
      [
        this.labourHoursData.permanentLabourHourlyRate || "0.00",
        this.labourHoursData.labourPermanentCost || "0.00",
        this.labourHoursData.labourPermanentHours || "0.00",
        this.labourHoursData.labourPermanentOvertime1_5xHours || "0.00",
        this.labourHoursData.labourPermanentOvertime2xHours || "0.00",
        this.labourHoursData.subcontactorHourRate || "0.00",
        this.labourHoursData.labourSubcontractorCost || "0.00",
        this.labourHoursData.labourSubcontractorHours || "0.00",
      ],
    ],
  });

  doc.text("Manual Inputs:", 10, doc.lastAutoTable.finalY + 10);
  doc.autoTable({
    startY: doc.lastAutoTable.finalY + 20,
    body: [
      ["Budgetary Sale Number", this.labourHoursData.budgetarySaleNumber || "0"],
      ["Budgetary Labour Cost", this.labourHoursData.budgetaryLabourCost || "0"],
      ["Budgetary Material Cost", this.labourHoursData.budgetaryMaterialsCost || "0"],
      ["Budgetary Profit Margin", this.labourHoursData.budgetaryProfitMargin || "0"],
    ],
  });
    const jobNumber = this.reportData.jobNumber || "Unknown_Job";
    // Save PDF
    doc.save(`Job_Profit_and_Loss_Report_${jobNumber}_${new Date().toISOString()}.pdf`);
  },
  exportToExcel() {
  if (!this.showReport || !this.reportData) {
    this.snackbarMessage = "No report available to download.";
    this.showSnackbar = true;
    return;
  }

  // Prepare CSV content
  const csvContent = [];

  // Header section
  csvContent.push(["Gricon Industries Pty Ltd - Job Profit and Loss Report"]);
  csvContent.push([]);
  csvContent.push(["183 Bergmans Road, Greenmount QLD 4751"]);
  csvContent.push(["Phone: 0417900707"]);
  csvContent.push([]);
  csvContent.push(["Date From:", this.reportData.startDate || "N/A"]);
  csvContent.push(["Date To:", this.reportData.endDate || "N/A"]);
  csvContent.push(["Generated On:", new Date().toLocaleString()]);
  csvContent.push([]);
  csvContent.push([]);

  // Summary Table
  csvContent.push([
    "Job Number",
    "Job Name",
    "Income ($)",
    "Cost ($)",
    "Expenses ($)",
    "Net Profit ($)",
    "Profit Margin (%)",
    "Total Labour Cost ($)",
    "Total Material Cost ($)",
  ]);
  csvContent.push([
    this.reportData.jobNumber || "None",
    this.reportData.jobName || "None",
    this.reportData.Income || "0.00",
    this.reportData.CostOfSales || "0.00",
    this.reportData.Expenses || "0.00",
    this.reportData.NetProfit || "0.00",
    this.reportData.ProfitMargin || "0.00",
    this.reportData.TotalLabour || "0.00",
    this.reportData.TotalMaterials || "0.00",
  ]);
  csvContent.push([]);
  csvContent.push([]);

  // Detailed Table
  csvContent.push(["Category Code", "Category Name", "Selected Period ($)"]);
  this.groupedAndSortedAccountDetails.forEach((row) => {
    if (row.isParent) {
      csvContent.push([row.ParentAccountName, "", ""]);
    } else {
      csvContent.push([
        row.DisplayID || " ",
        row.Name || " ",
        row.Total || "0.00",
      ]);
    }
  });
  csvContent.push([]);
  csvContent.push([]);

  // Total Section
  csvContent.push(["Total Income", this.reportData.Income || "0.00"]);
  csvContent.push([
    "Total Cost of Sales",
    this.reportData.CostOfSales || "0.00",
  ]);
  csvContent.push(["Gross Profit", this.reportData.GrossProfit || "0.00"]);
  csvContent.push(["Total Expense", this.reportData.Expenses || "0.00"]);
  csvContent.push([
    "Operating Profit",
    this.reportData.OperatingProfit || this.reportData.GrossProfit || "0.00",
  ]);
  csvContent.push(["Net Profit", this.reportData.NetProfit || "0.00"]);
  csvContent.push([]);
  csvContent.push([]);

  // Labour Hours Table
  csvContent.push([
    "Permanent Labour Hourly Rate ($)",
    "Permanent Labour Cost ($)",
    "Permanent Labour Hours (hr)",
    "Permanent Labour Overtime Hours (1.5x)",
    "Permanent Labour Overtime Hours (2x)",
    "Subcontractor Labour Hourly Rate ($)",
    "Subcontractor Labour Cost ($)",
    "Subcontractor Labour Hours (hr)",
  ]);
  csvContent.push([
    this.labourHoursData.permanentLabourHourlyRate || "None",
    this.labourHoursData.labourPermanentCost || "None",
    this.labourHoursData.labourPermanentHours || "0.00",
    this.labourHoursData.labourPermanentOvertime1_5xHours || "0.00",
    this.labourHoursData.labourPermanentOvertime2xHours || "0.00",
    this.labourHoursData.subcontactorHourRate || "0.00",
    this.labourHoursData.labourSubcontractorCost || "0.00",
    this.labourHoursData.labourSubcontractorHours || "0.00",
  ]);
  csvContent.push([]);
  csvContent.push([]);

  // Manual Inputs Section
  csvContent.push(["Manual Inputs"]);
  csvContent.push(["Budgetary Sale Number", this.labourHoursData.budgetarySaleNumber || "0"]);
  csvContent.push(["Budgetary Labour Cost", this.labourHoursData.budgetaryLabourCost || "0"]);
  csvContent.push(["Budgetary Material Cost", this.labourHoursData.budgetaryMaterialsCost || "0"]);
  csvContent.push(["Budgetary Profit Margin", this.labourHoursData.budgetaryProfitMargin || "0"]);
  csvContent.push([]);

  // Convert array to CSV format
  const csvData = csvContent
    .map((row) => row.map((cell) => `"${cell}"`).join(",")) // Wrap each cell in quotes for safety
    .join("\n");

  // Download CSV file
  const blob = new Blob([csvData], { type: "text/csv" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  const jobNumber = this.reportData.jobNumber || "Unknown_Job";
  link.download = `Job_Profit_and_Loss_Report_${jobNumber}_${new Date().toISOString()}.csv`;
  link.click();
},
},
};
</script>

<style scoped>
.text-h5 {
  font-size: 1.25rem;
  font-weight: 500;
}
.btn {
  background-color: #dc0f93 !important;
  color: #fff;
}
.btn1 {
  background-color: black !important;
  color: #fff;
}
.small-download-btn {
  width: auto !important; /* Ensure the button adjusts to content width */
  padding: 0 10px !important; /* Add some padding */
  font-size: 12px; /* Adjust the font size for a smaller button */
  background-color: black !important; /* Button background color */
  color: white !important; /* Button text color */
}

.v-row.justify-end {
  justify-content: flex-end; /* Align to the right */
}
</style>
