<template>
  <div>
    <h4>Financial Report Graphs</h4>
    <div v-if="isLoading" class="d-flex justify-center my-5">
      <v-progress-circular
        indeterminate
        color="primary"
        size="50"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-container fluid>
        <!-- Graphs in a Responsive Row -->
        <v-row dense>
          <!-- Overall Metrics -->
          <v-col cols="12" sm="12" md="6" lg="6">
            <div class="graph-card">
              <e-charts
               ref="zoomedChart"
                :options="chartOptions1"
                style="height: 400px; width: 100%"
              ></e-charts>
              <v-icon
                small
                color="primary"
                class="zoom-icon"
                @click="openGraph(chartOptions1, '')"
              >
                mdi-magnify
              </v-icon>
            </div>
          </v-col>

          <!-- Expenses Breakdown -->
          <v-col cols="12" sm="12" md="6" lg="6">
            <div class="graph-card">
              <e-charts
              ref="zoomedChart"
                :options="chartOptions3"
                style="height: 400px; width: 100%"
              ></e-charts>
              <v-icon
                small
                color="primary"
                class="zoom-icon"
                @click="openGraph(chartOptions3, '')"
              >
                mdi-magnify
              </v-icon>
            </div>
          </v-col>

          <!-- Profit Margin by Category -->
          <v-col cols="12" sm="12" md="6" lg="6">
            <div class="graph-card">
              <e-charts
              ref="zoomedChart"
                :options="chartOptions2"
                style="height: 400px; width: 100%"
              ></e-charts>
              <v-icon
                small
                color="primary"
                class="zoom-icon"
                @click="openGraph(chartOptions2, '')"
              >
                mdi-magnify
              </v-icon>
            </div>
          </v-col>

          <!-- Labour Hours Breakdown -->
          <v-col cols="12" sm="12" md="6" lg="6">
            <div class="graph-card">
              <e-charts
              ref="zoomedChart"
                :options="chartOptions4"
                style="height: 400px; width: 100%"
              ></e-charts>
              <v-icon
                small
                color="primary"
                class="zoom-icon"
                @click="openGraph(chartOptions4, '')"
              >
                mdi-magnify
              </v-icon>
            </div>
          </v-col>
        </v-row>

        <!-- Modal for Zoomed Graph -->
        <v-dialog v-model="zoomedGraph.show" max-width="90%" persistent>
          <v-card>
            <v-card-title>
              {{ zoomedGraph.title }}
              <v-spacer></v-spacer>
              <v-btn icon @click="closeGraph">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <e-charts
                ref="zoomedChart"
                :options="zoomedGraph.options"
                style="height: 80vh; width: 100%"
              ></e-charts>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-container>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import ECharts from "vue-echarts/components/ECharts";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/pie";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/title";
import "echarts/lib/component/legend";

export default defineComponent({
  name: "GraphComponent",
  components: {
    ECharts,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
    labourData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      chartOptions1: {}, // Overall metrics
      chartOptions2: {}, // Profit margin with color coding
      chartOptions3: {}, // Expenses breakdown
      chartOptions4: {}, // Labour hours breakdown
      zoomedGraph: {
        show: false,
        options: null,
        title: "",
      },
    };
  },
  watch: {
    reportData: {
      immediate: true,
      handler(newData) {
        if (newData) {
          this.prepareCharts(newData);
          this.isLoading = false;
        }
      },
    },
    labourData: {
      immediate: true,
      handler(newLabourData) {
        if (newLabourData) {
          this.prepareLabourChart(newLabourData);
        }
      },
    },
  },
  methods: {
    prepareCharts(data) {
      // Graph 1: Overall Metrics
      this.chartOptions1 = {
        title: { text: "Overall Metrics", left: "center" },
        tooltip: { trigger: "axis" },
        xAxis: {
          type: "category",
          data: ["Income", "Cost", "Expenses", "Net Profit"],
        },
        yAxis: { type: "value" },
        series: [
          {
            type: "bar",
            data: [
              data.Income || 0,
              data.CostOfSales || 0,
              data.Expenses || 0,
              data.NetProfit || 0,
            ],
            itemStyle: {
              color: (params) => {
                // Define unique colors for each bar
                const colors = ["#3b82f6", "#ef4444", "#10b981", "#f59e0b"]; // Blue, Red, Green, Yellow
                return colors[params.dataIndex];
              },
            },
          },
        ],
      };

      // Graph 2: Profit Margin with Color Coding
      this.chartOptions2 = {
        title: { text: "Profit Margin", left: "center" },
        tooltip: { trigger: "axis" },
        xAxis: { type: "category", data: ["Profit Margin"] },
        yAxis: { type: "value" },
        series: [
          {
            type: "bar",
            data: [
              {
                value: data.ProfitMargin || 0,
                itemStyle: {
                  color:
                    data.ProfitMargin > 25
                      ? "green"
                      : data.ProfitMargin >= 12
                      ? "blue"
                      : "red",
                },
              },
            ],
          },
        ],
      };

      // Graph 3: Expenses Breakdown
      this.chartOptions3 = {
        title: { text: "Expenses Breakdown", left: "center" },
        tooltip: { trigger: "item" },
        legend: { bottom: "10" },
        series: [
          {
            type: "pie",
            radius: "50%",
            data: [
              { value: data.TotalLabour || 0, name: "Labour" },
              { value: data.TotalMaterials || 0, name: "Materials" },
              { value: data.Expenses || 0, name: "Overheads" },
            ],
          },
        ],
      };
    },

      prepareLabourChart(labourData) {
      // Graph 4: Labour Hours Breakdown
      this.chartOptions4 = {
        title: { text: "Subcontractor Metrics", left: "center" },
        tooltip: { trigger: "axis" },
        xAxis: {
          type: "category",
          data: ["Rate", "Cost", "Hours", "1.5x Overtime", "2x Overtime"],
        },
        yAxis: { type: "value" },
        series: [
          {
            type: "bar",
            data: [
              labourData.subcontactorHourRate || 0,
              labourData.labourSubcontractorCost || 0,
              labourData.labourSubcontractorHours || 0,
              labourData.labourPermanentOvertime1_5xHours || 0,
              labourData.labourPermanentOvertime2xHours || 0,
            ],
            itemStyle: {
              color: (params) => {
                const colors = [
                  "#3b82f6", // Rate - Blue
                  "#10b981", // Cost - Green
                  "#f59e0b", // Hours - Yellow
                  "#ef4444", // 1.5x Overtime - Red
                  "#8b5cf6", // 2x Overtime - Purple
                ];
                return colors[params.dataIndex];
              },
            },
          },
        ],
      };
    },
    openGraph(chartOptions, title) {
  this.zoomedGraph.options = { ...chartOptions }; // Clone options for proper rendering
  this.zoomedGraph.title = title;
  this.zoomedGraph.show = true;

  // Delay to ensure modal is fully rendered before resizing
  this.$nextTick(() => {
    setTimeout(() => {
      const chart = this.$refs.zoomedChart;
      if (chart) {
        chart.resize(); // Force resize to calculate dimensions correctly
      }
    }, 200); // Slight delay to let the modal render
  });
},
    closeGraph() {
      this.zoomedGraph.show = false;
      this.zoomedGraph.options = null; // Clear options to avoid rendering issues
    },
  },
});
</script>

<style scoped>
h4 {
  text-align: center;
  margin-bottom: 20px;
}

.v-col {
  margin-bottom: 20px; /* Add spacing between graphs */
}

.graph-card {
  position: relative;
  background: #f1efef; /* White background */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06); /* 3D shadow */
  padding: 20px; /* Space inside the card */
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out; /* Smooth transition for hover effect */
}

.graph-card:hover {
  transform: translateY(-10px); /* Move the card up slightly */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.1); /* Enhance the shadow */
}

.zoom-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: white;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 5px;
}
</style>